<template>
	<!-- 返回頂部 -->
	<div class="goTop">
		<img src="@/assets/images/btn_gotop.png" alt="" class="w-100">
	</div> 

	<div class="mission row justify-content-center text-secondary pt-3 pt-lg-4" data-aos="fade-up" data-aos-duration="1500">
		<div class="eightviews col-12 col-xl-10 mb-3 mb-md-4">			
			<div class="mission__main px-lg-3 pt-2 pt-md-0">
				<img v-if="deviceMode != 'mobile'" class="mission__title d-block mx-auto mb-4" src="@/assets/images/mission/img_v_title.png" alt="淡水八景從哪來">
				<img v-else class="mission__title d-block mx-auto mb-4 mb-md-0" src="@/assets/images/mission/img_v_title_s.png" alt="淡水八景從哪來">
			</div>
			<div v-if="deviceMode == 'deskTop'" class="link text-center">
				<a href="#views_1"  @click.prevent="scrollToEl('1')" class="text-secondary">港灣夕照</a> / 
				<a href="#views_2"  @click.prevent="scrollToEl('2')" class="text-secondary">淡海紅林</a> / 
				<a href="#views_3"  @click.prevent="scrollToEl('3')" class="text-secondary">雲舞山嵐</a> / 
				<a href="#views_4"  @click.prevent="scrollToEl('4')" class="text-secondary">魅力淡水</a> / 
				<a href="#views_5"  @click.prevent="scrollToEl('5')" class="text-secondary">古蹟攬勝</a> / 
				<a href="#views_6"  @click.prevent="scrollToEl('6')" class="text-secondary">宗教勝境</a> / 
				<a href="#views_7"  @click.prevent="scrollToEl('7')" class="text-secondary">匠心凝華</a> / 
				<a href="#views_8"  @click.prevent="scrollToEl('8')" class="text-secondary">百花綴櫻</a>
			</div>
		</div>
		<div class="eightviews col-12 col-xl-10">
			<div id="views_1" class="eightviews__wrap" @click="toggleView('1')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-2">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="des__title col-5 mb-lg-4">
									<img class="" src="@/assets/images/mission/img_s_t1.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">淡水河口、海港等夕陽景致</h3>
								</div>
								<div class="col-5 col-lg-6 px-0">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_1.png" alt="">
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('1')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水漁人碼頭') }" class="text-secondary">淡水漁人碼頭</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('福容大飯店＆漁人碼頭情人塔') }" class="text-secondary">福容大飯店</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡海情人橋＆漁人舞台') }" class="text-secondary">淡海情人橋＆漁人舞台</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水海關碼頭園區') }" class="text-secondary">淡水海關碼頭園區</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('沙崙海灘') }" class="text-secondary">沙崙海灘</router-link> / 
								<router-link target="_blank" :to="{ name: 'scene', hash: '#淡江大橋' }"  class="text-secondary">淡江大橋(進行式)</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_1.png" alt="">
			</div>
			<div id="views_2" class="eightviews__wrap" @click="toggleView('2')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-3">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="col-5">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_2.png" alt="">
								</div>
								<div class="des__title col-5 col-lg-6 mb-lg-4 px-0">
									<img class="" src="@/assets/images/mission/img_s_t2.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">淡水河口自然生態、<br class="d-none d-xl-block">濕地等景致</h3>
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('2')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('紅樹林自然保留區＆生態教育館') }" class="text-secondary">紅樹林自然保留區＆生態教育館</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾藝文休閒園區(將捷金鬱金香酒店、禮萊廣場)') }" class="text-secondary">滬尾藝文休閒園區(將捷金鬱金香酒店、禮萊廣場)</router-link> /  
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水金色水岸(含自行車道、淡水第一漁港)') }" class="text-secondary">淡水金色水岸(含自行車道/淡水第一漁港)</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_2.png" alt="">
			</div>
			<div id="views_3" class="eightviews__wrap" @click="toggleView('3')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-2">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="des__title col-5 col-lg-6 mb-lg-4">
									<img class="" src="@/assets/images/mission/img_s_t3.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">依山傍水及視覺可及<br class="d-none d-xl-block">山嵐美景之間等景致</h3>
								</div>
								<div class="col-5 col-lg-6 px-0">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_3.png" alt="">
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('3')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡江大學宮燈教室') }" class="text-secondary">淡江大學宮燈教室</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾藝文休閒園區(將捷金鬱金香酒店、禮萊廣場)') }" class="text-secondary">滬尾藝文休閒園區(將捷金鬱金香酒店/禮萊廣場)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('無極真元天壇(天元宮)') }" class="text-secondary">無極真元天壇(天元宮)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('錦繡莊文化園區') }" class="text-secondary">錦繡莊文化園區</router-link> / <br class="d-none d-md-block">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('一滴水紀念館') }" class="text-secondary">一滴水紀念館</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('楓樹湖＆登山步道') }" class="text-secondary">楓樹湖＆登山步道</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('緣道觀音廟') }" class="text-secondary">緣道觀音廟</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾休閒農業區') }" class="text-secondary">滬尾休閒農業區</router-link> / 
								<router-link target="_blank" :to="{ name: 'scene', hash: '#草堂花譜溼地生態敎育站' }"  class="text-secondary">草堂花譜</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_3.png" alt="">
			</div>
			<div id="views_4" class="eightviews__wrap" @click="toggleView('4')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-3">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="col-5">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_4.png" alt="">
								</div>
								<div class="des__title col-5 col-lg-6 mb-lg-4 px-0">
									<img class="" src="@/assets/images/mission/img_s_t4.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">在地人文風情、藝術節慶<br class="d-none d-xl-block">多元活力樣貌</h3>
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('4')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水環境藝術節') }" class="text-secondary">淡水環境藝術節</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水福佑宮') }" class="text-secondary">福佑宮-媽祖祭</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('清水巖祖師廟') }" class="text-secondary">清水巖祖師廟-繞境</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡海輕軌(含藍海橋自行車道)') }" class="text-secondary">淡海輕軌(含藍海橋自行車道)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水老街') }" class="text-secondary">淡水老街</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('雲門劇場') }" class="text-secondary">雲門劇場</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾藝文休閒園區(將捷金鬱金香酒店、禮萊廣場)') }" class="text-secondary">滬尾藝文休閒園區(將捷金鬱金香酒店、禮萊廣場)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水重建街') }" class="text-secondary">淡水重建街</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡海情人橋＆漁人舞台') }" class="text-secondary">淡海情人橋＆漁人舞台</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_4.png" alt="">
			</div>
			<div id="views_5" class="eightviews__wrap" @click="toggleView('5')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-2">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="des__title col-5 col-lg-6 mb-lg-4">
									<img class="" src="@/assets/images/mission/img_s_t5.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">經歷多國文化交流產生<br class="d-none d-xl-block">多元歷史古蹟風華</h3>
								</div>
								<div class="col-5 col-lg-6 px-0">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_5.png" alt="">
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('5')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('紅毛城園區(含前清英國領事館)') }" class="text-secondary">紅毛城園區(前清英國領事館)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾砲臺公園＆滬尾礮(砲)臺') }" class="text-secondary">滬尾砲臺公園＆滬尾礮(砲)臺</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('真理大學建物群') }" class="text-secondary">真理大學建物群</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水八角塔') }" class="text-secondary">淡水八角塔</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('小白宮') }" class="text-secondary">小白宮</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水紅樓') }" class="text-secondary">淡水紅樓</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水文化園區-殼牌倉庫') }" class="text-secondary">淡水文化園區-殼牌倉庫</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水日本警官宿舍') }" class="text-secondary">淡水日本警官宿舍</router-link> / 							
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('多田榮吉故居') }" class="text-secondary">多田榮吉故居</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('得忌利士洋行') }" class="text-secondary">得忌利士洋行</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('馬偕街群') }" class="text-secondary">馬偕街群</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('程氏古厝') }" class="text-secondary">程氏古厝</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_5.png" alt="">
			</div>
			<div id="views_6" class="eightviews__wrap" @click="toggleView('6')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-3">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="col-5">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_6.png" alt="">
								</div>
								<div class="des__title col-5 col-lg-6 mb-lg-4 px-0">
									<img class="" src="@/assets/images/mission/img_s_t6.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">淡水廟宇、教堂等具信仰意涵之場域</h3>
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('6')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('無極真元天壇(天元宮)') }" class="text-secondary">無極真元天壇(天元宮)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水龍山寺') }" class="text-secondary">淡水龍山寺</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('緣道觀音廟') }" class="text-secondary">緣道觀音廟</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('清水巖祖師廟') }" class="text-secondary">清水巖祖師廟</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水福佑宮') }" class="text-secondary">淡水福佑宮</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('行忠堂(含逍遙園)') }" class="text-secondary">行忠堂(含逍遙園)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('鄞山寺') }" class="text-secondary">鄞山寺</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('新北市忠烈祠') }" class="text-secondary">新北市忠烈祠</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('真理大學建物群') }" class="text-secondary">真理大學建物群(教堂)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('淡水老街') }" class="text-secondary">淡水老街(淡水禮拜堂)</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_6.png" alt="">
			</div>
			<div id="views_7" class="eightviews__wrap" @click="toggleView('7')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-2">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="des__title col-5 col-lg-6 mb-lg-4">
									<img class="" src="@/assets/images/mission/img_s_t7.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">特定技藝之人文場域，<br class="d-none d-xl-block">職人精神與美學</h3>
								</div>
								<div class="col-5 col-lg-6 px-0">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_7.png" alt="">
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('7')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('齊柏林空間') }" class="text-secondary">齊柏林空間</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('雲門劇場') }" class="text-secondary">雲門劇場</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('錦繡莊文化園區') }" class="text-secondary">錦繡莊文化園區</router-link> / <br class="d-none d-md-block">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('琉傳天下琉星花園') }" class="text-secondary">琉傳天下琉星花園</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾休閒農業區') }" class="text-secondary">滬尾休閒農業區</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('馬偕街群') }" class="text-secondary">馬偕街群(陳澄波戶外美術館)</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_7.png" alt="">
			</div>
			<div id="views_8" class="eightviews__wrap" @click="toggleView('8')">
				<div class="eightviews__contain">
					<div class="row">
						<div class="col-lg-7 offset-lg-3">
							<div class="des__wrap row align-items-center align-items-lg-end">
								<div class="col-5">
									<img class="des__img mb-lg-2" src="@/assets/images/mission/img_s_8.png" alt="">
								</div>
								<div class="des__title col-5 col-lg-6 mb-lg-4 px-0">
									<img class="" src="@/assets/images/mission/img_s_t8.png" alt="">
									<h3 class="font-weight-bold mt-2 mt-lg-3">以櫻花接序各式花卉<br class="d-none d-xl-block">植物交織之美景</h3>
								</div>
								<div class="col-2 d-lg-none text-center">
									<span class="arrow"></span>
								</div>
							</div>
							<div class="link d-lg-block" :class="{'hide': !eightViews.includes('8')}">
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('無極真元天壇(天元宮)') }" class="text-secondary">無極真元天壇(天元宮)</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾櫻花大道') }" class="text-secondary">滬尾櫻花大道</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('緣道觀音廟') }" class="text-secondary">緣道觀音廟</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('楓樹湖＆登山步道') }" class="text-secondary">楓樹湖-木蓮花＆登山步道</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('滬尾休閒農業區') }" class="text-secondary">滬尾休閒農業區-紫藤</router-link> / 
								<router-link target="_blank" :to="{ name: 'vote', hash: '#'+findID('行忠堂(含逍遙園)') }" class="text-secondary">行忠堂(含逍遙園)-美人樹</router-link> / 
								<router-link target="_blank" :to="{ name: 'scene', hash: '#草堂花譜溼地生態敎育站' }"  class="text-secondary">草堂花譜</router-link>
							</div>
						</div>
					</div>
				</div>
				<img class="d-none d-lg-block w-100" src="@/assets/images/mission/bg_s_8.png" alt="">
			</div>
		</div>
	</div>
</template>
<script>
import $ from 'jquery';

export default {
	data() {
		return {
			VoteList:[],
			eightViews:[]
		}
	},
	created() {
		this.ReadData();    
	},
	mounted() {
	},
	updated(){
		$(window).scroll(function(){
			if($(window).scrollTop()>=1000){
				$(".goTop").fadeIn(300).click(function() {
					$('html, body').stop(true, false).animate({
						scrollTop: 0
					}, 'slow');
					return false;
				});
			} else{
				$(".goTop").fadeOut(300);
			}
		});
	},
	methods: {
		ReadData:function(){
			var FD = new FormData();
				FD.append('mode', 'sct');
				FD.append('data', '{"class":"Scene","lang":"zh-TW","VoteCount":true,"Imgs":true}');

			this.axios({
				method: 'post',
				url: 'RApi',
				data: FD,
				headers: {'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				this.VoteList=response.data.data.filter(item => item.TitleAlias!='');
				// console.log(this.VoteList);
			})
		},
		findID(title){
			let viewItem = this.VoteList.filter(function(item){
				if(item.GroupTitle!==''){
					return item.GroupTitle==title
				} else{
					return (item.TitleAlias).replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,"")==title
				}
			});
			if(viewItem[0]!==undefined){
				return viewItem[0].ID;
			}
		},
		// 移除Html標籤
		removeHtml(str){
			return str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,"");
		},
		toggleView(s){
			if(this.eightViews.includes(s)){
				const index = this.eightViews.indexOf(s);
				if (index > -1) {
					this.eightViews.splice(index, 1);
				}
			} else{
				this.eightViews.push(s);
			}
		},
		scrollToEl(id){
			$('html, body').animate({
				scrollTop: $("#views_"+id).offset().top
			}, 1000);
		}
	}
}
</script>